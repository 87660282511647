import * as React from "react"
import { Helmet } from "react-helmet"

const logo = require("../../images/desdeMalta.png")
const ana = require("../../images/ana_circle.png")

export interface Props {
  title: string;
  isPost?: boolean;
  description: string;
  link: string;
  image?: string;
  type?: string;
  children?: any;
  structuredData?: string;
}

const structuredDataOrganization = `{
		"@context": "http://schema.org",
		"@type": "Organization",
		"name": "Desde Malta",
		"legalName": "Desde Malta",
		"url": "https://desdemalta.com/",
		"description": "Consultoría especializada en la planeación de viaje de estudios de inglés a Malta.",
		"logo": "https://desdemalta.com${logo}",
		"hasCredential": {
		  "@type": "EducationalOccupationalCredential",
		  "credentialCategory": "certificate",
		  "competencyRequired": "Quality Agent",
		  "recognizedBy": {
		    "@type": "Organization",
		    "name": "Quality English Ltd"
		  }
		},
		"founders": [{
			"@type": "Person",
			"name": "Ana Maria Cardona Ochoa",
			"image": "https://desdemalta.com${ana}",
			"sameAs": "https://www.linkedin.com/in/anamariacardona/"
		}],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "contacto@desdemalta.com",
			"telephone": "+35699578513",
			"contactType": "reservations",
			"availableLanguage": ["en","es","Portuguese"]
		}],
		"address": {
			"@type": "PostalAddress",
			"addressCountry": "MT",
      "addressLocality": "St Julians",
      "streetAddress": "Triq Paceville 3"
		},
		"sameAs": [
        "https://www.facebook.com/DesdeMalta/",
        "https://www.youtube.com/channel/UCeA-pbqZpgMPw2sFdKOvCtQ",
        "https://www.instagram.com/desdemaltaoficial/"
    ]
  	}`

const CustomHelmet = ({ structuredData, title, description, isPost, image = require("../../images/juli-y-ana-transparente.png"), link, type = "website", children }: Props) => (
  <Helmet htmlAttributes={{
    lang: "es",
  }}>
    <title>{title}</title>
    <meta name="description" content={description}/>
    <meta property="og:locale" content="es_ES"/>
    <meta property="og:type" content={type}/>
    <meta property="og:title" content={title}/>
    <meta property="og:description" content={description}/>
    <meta property="og:url" content={`https://desdemalta.com` + link}/>
    <meta property="og:image" content={isPost ? image : "https://desdemalta.com" + image}/>
    <meta property="fb:app_id" content={"313205169545350"}/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:description" content={description}/>
    <meta name="twitter:title" content={title}/>
    <meta name="twitter:image" content={"https://desdemalta.com" + image}/>
    <meta name="twitter:creator" content={"@desdemalta"}/>
    <link rel="canonical" href={`https://desdemalta.com${link}`}/>
    {(link !== "/paso-a-paso/" && link !== "/covid-19/" && link !== "/conocenos/" && link !== "/articulos/" && link !== "/testimonios/" && link !== "/auto-estudio/") &&
    (<script type="application/ld+json">
      {structuredData || structuredDataOrganization}
    </script>)}
  </Helmet>
)

export default CustomHelmet
