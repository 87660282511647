import Layout from "../components/layout"
import * as React from "react"
import CustomHelmet from "../components/CustomHelmet"

// @ts-ignore
import styles from "./contacto.module.scss"
// @ts-ignore
import envelope from "../images/envelope.svg"
// @ts-ignore
import desdeMaltaLocation from "../images/desdeMaltaLocation.png"
import { CallToAction } from "../components/CallToAction"

const Contacto = () => (
  <Layout>
    <CustomHelmet
      title={"Contacto. Contactar a Desde Malta"}
      description={"La planeacion de tu curso de ingles empieza aqui"}
      link={"/contacto/"}
    />
    <div className={styles.general}>
      <h1>Planea tu viaje</h1>
      <section>
        Hora de ponerte en contacto con nosotros para empezar con la planeación de tu curso de inglés.
        Ingresa tus datos en el formulario que encontrarás a continuación para que iniciemos con la perfilación de tu
        plan de viaje.
      </section>
      <a href={"https://mi.desdemalta.com/bienvenido/"} target={"_blank"} rel="noopener noreferrer"><CallToAction
        className={styles.callToAction}>Formulario
        de inicio</CallToAction></a>
    </div>
    <div className={styles.contact}>
      <div className={styles.email}>
        <h2 className={styles.header}>Sigues con dudas?</h2>
        <img src={envelope} alt={"envelope"}/>
        <section>
          Si tienes preguntas, o simplemente nos quieres mandar un mensaje:
        </section>
        <section>
          mandanos un coreo a <span className={styles.mailLink}>contacto@desdemalta.com</span>
        </section>
      </div>
      <div className={styles.location}>
        <img src={desdeMaltaLocation} alt={"desde malta location"}/>
        <div>Desde Malta - Triq Paceville 3 - St Julians - Malta</div>
      </div>
    </div>
  </Layout>
)


export default Contacto